$(document).ready(function () {

  // Products Slider
  $('.slider-home-products').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    pauseOnHover: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  // Slider Info Bottom
  $('.slider-about').on(`init reInit`, function (event, slick) {
    $('.counter-bottom').text(1 + ' / ' + slick.slideCount);
  })
  $('.slider-about').on(`afterChange`, function (event, slick, currentSlide, nextSlide) {
    $('.counter-bottom').text(currentSlide + 1 + ' / ' + slick.slideCount);
  })
  $('.slider-about').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    dots: false,
    pauseOnHover: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000
  });

  // Products Slider
  $('.slider-recipes').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    pauseOnHover: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  // Sticky menu on scroll
  $(function () {
    var shrinkHeader = 650;
    $(window).scroll(function () {
      var scroll = getCurrentScroll();
      if (scroll >= shrinkHeader) {
        $('header').addClass('shrink');
        $('body').addClass('body-expand');
      } else {
        $('header').removeClass('shrink');
        $('body').removeClass('body-expand');
      }
    });

    function getCurrentScroll() {
      return window.pageYOffset || document.documentElement.scrollTop;
    }
  });

  // Smooth scroll on anchors
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href*="nav"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top - 50
          }, 500, function () {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) { // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            }
            ;
          });
        }
      }
    });

    // overlay_hover_products();
    //
    // $(window).resize(function () {
    //     overlay_hover_products();
    // });
});

function overlay_hover_products() {
    if($(window).width() > 1280) {
        const overlay = document.querySelector('.products-two .nav-tabs-parent .overlay');
        const nav_list = document.querySelectorAll('.products-two .nav-tabs-parent a');

        let position = nav_list[0].getBoundingClientRect();
        nav_list[0].classList.add('first');
        overlay.classList.add('active');
        overlay.style.left = nav_list[0].offsetLeft + 'px';
        overlay.style.top = nav_list[0].offsetTop + 'px';
        overlay.style.height = position.height + 'px';
        overlay.style.width = position.width + 'px';

        nav_list.forEach((list) => {
            list.addEventListener('mouseover', () => {
                let position = list.getBoundingClientRect();
                overlay.classList.add('active');
                list.classList.remove('first');
                overlay.style.left = list.offsetLeft + 'px';
                overlay.style.top = list.offsetTop + 'px';
                overlay.style.height = position.height + 'px';
                overlay.style.width = position.width + 'px';
            });
            list.addEventListener('mouseout', () => {
                overlay.classList.remove('active');
            });
        });
    }
}
